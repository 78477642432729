import request from '@/utils/request'

// 退换列表
export function getRefundList(params) {
    return request({
        url: '/v1/mall/refund/list?type=' + params.type + '&page=' + params.page + '&rows=' + params.rows,
        method: 'get',
    })
}

// 快递公司
export function getCompany() {
    return request({
        url: '/v1/mall/refund/express/company',
        method: 'get',
    })
}

// 退换申请
export function applyRefund(params) {
    return request({
        url: '/v1/mall/refund/apply',
        method: 'post',
        data: params
    })
}

// 退换理由
export function ReasonRefund() {
    return request({
        url: '/v1/mall/refund/reason',
        method: 'get',
    })
}

// 退换进度
export function ProgressRefund(orderId) {
    return request({
        url: '/v1/mall/refund/progress?orderId=' + orderId,
        method: 'get',
    })
}

// 图片上传
export function getOss() {
    return request({
        url: '/v1/mall/oss',
        method: 'get',
    })
}

// 取消申请
export function cancelRefund(data) {
    return request({
        url: '/v1/mall/refund/cancel',
        method: 'post',
        data: {
            orderId: data
        }
    })
}

// 提交快递单号
export function submitRefund(data) {
    return request({
        url: '/v1/mall/refund/submit',
        method: 'post',
        data: data
    })
}

// 已解决
export function confirmRefund(data) {
    return request({
        url: '/v1/mall/refund/confirm',
        method: 'post',
        data: {
            orderId: data
        }
    })
}
